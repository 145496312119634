<template>
    <div v-if="activeComponent" id="detail-wrapper" class="fixed inset-0 overflow-hidden bg-gray-400 bg-opacity-50" style="z-index: 998;">
        <div class="flex items-center justify-center absolute inset-0 overflow-hidden">
            <component :is="activeComponent"/>
        </div>
    </div>
</template>

<script>

import AddCompanyAddress from "./company/components/AddCompanyAddress";
import AddCompanyUser from "./company/components/AddCompanyUser";
import SendInvitations from "./company/components/SendInvitations";
import {DETAIL_PANEL_KEYS} from "../../constants";
import {find} from "lodash";
import AddSupplier from "./suppliers/components/AddSupplier";
import NewMessage from "@/master/views/Home/messages/components/NewMessage";
import AddBusinessUnit from "@/master/views/Home/company/components/AddBusinessUnit";
import AddGroup from "@/master/views/Home/company/components/AddGroup";

const COMPONENTS = [
    {name: 'add-company-address', component: AddCompanyAddress, key: DETAIL_PANEL_KEYS.EDIT_COMPANY_ADDRESS},
    {name: 'add-company-user', component: AddCompanyUser, key: DETAIL_PANEL_KEYS.EDIT_COMPANY_USER},
    {name: 'add-business-unit', component: AddBusinessUnit, key: DETAIL_PANEL_KEYS.EDIT_BUSINESS_UNIT},
    {name: 'add-group', component: AddGroup, key: DETAIL_PANEL_KEYS.EDIT_GROUP},
    {name: 'send-invitations', component: SendInvitations, key: DETAIL_PANEL_KEYS.SEND_INVITATIONS},
    {name: 'edit-supplier', component: AddSupplier, key: DETAIL_PANEL_KEYS.EDIT_SUPPLIER},
    {name: 'new-message', component: NewMessage, key: DETAIL_PANEL_KEYS.NEW_MESSAGE},
]

export default {
    name: "Detail",
    data() {
        return {
            components: COMPONENTS,
            activeComponent: null
        }
    },

    watch: {
        '$store.state.master.detail': function () {
            this.setActiveComponent()
        }
    },

    methods: {
        setActiveComponent() {
            if (this.$store.getters['master/detailOpen']) {
                const component = find(this.components, comp => comp.key === this.$store.getters['master/detail'].key)
                if (component) {
                    this.activeComponent = component.component
                }
            }
        },

        handleKeypress(e) {
            if (e.key == 'Escape') {
                this.$store.commit('master/closeDetail')
            }
        }
    },

    components: Object.assign({}, ...(COMPONENTS.map(item => ({[item.name]: item.component})))),

    created() {
        this.setActiveComponent();
        window.addEventListener('keydown', this.handleKeypress);
    },

    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeypress);
    },


}
</script>

<style scoped>

</style>
