<template>
    <section :class="['absolute inset-y-0 pl-10 max-w-full flex', centered ? '' : 'right-0']">
        <div class="w-screen max-w-5xl">
            <div class="h-screen flex flex-col bg-white shadow-xl overflow-hidden">
                <div class="relative h-full">

                    <autralis-success-comp :show="invited" text="The users invited successfully."/>
                    <autralis-error-comp :show="error !== null" :text="error"/>

                    <header id="send-invitation-header" class="space-y-1 py-2 px-4 bg-blue-700">
                        <div class="flex items-center justify-between">
                            <div class="flex space-x-4">
                                <button @click="invite"
                                        class="primary-button">
                                    <span v-if="inviting" class="flex items-center">
                                        <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Inviting</translate> ...
                                    </span>
                                    <span v-if="!inviting"><i class="fal fa-paper-plane mt-1 mr-1"></i> Invite</span>

                                </button>
                            </div>
                            <div class="flex">
                                <div class="hidden h-6 md:flex items-center mr-4">
                                    <i class="fas fa-arrows-alt-h text-white hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer mt-1" @click="centered = !centered"/>
                                </div>
                                <div class="h-6 flex items-center">
                                    <i class="fal fa-times text-white text-xl text-white hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer" @click="close"/>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div v-if="inviting" class="absolute inset-x-0 bottom-0 bg-black bg-opacity-30 z-20" :style="{top: headerHeight + 'px'}"/>
                    <div class="mt-6 px-6">
                        <div class="flex items-center w-full border-b border-gray-200 mb-6 pb-6">
                            <div class="pr-4 text-blue-700 text-3xl">
                                <i class="fal fa-paper-plane"></i>
                            </div>
                            <div class="pl-2">
                                <div class="text-gray-600 text-2xl font-bold">Send invitations</div>
                                <div class="text-gray-600 text-sm">Please enter email addresses separated with comma's to send invitation mail for this company.</div>
                            </div>
                        </div>
                    </div>


                    <div class="flex flex-col w-full px-6">
                        <textarea rows="3"
                                  :class="[
                                      'w-full shadow-sm block w-full sm:text-sm border rounded-md focus:outline-none focus:ring-0 placeholder-gray-300',
                                      formErrors['emails'] ? 'border-red-700' : 'border-gray-300'
                                      ]"
                                  placeholder="john.doe@company.com, jane.doe@company.com"
                                  v-model="emails"
                        />
                        <span v-if="formErrors['emails'] !== undefined" class="mt-1 text-red-700 text-xs"><i class="fal fa-exclamation-triangle text-sm mr-2"/>
                            {{ formErrors['emails'] }}
                        </span>
                    </div>


                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SuccessBox from "@/components/success-box";
import ErrorBox from "@/components/error-box";

import {handleUserInvitations} from "../handlers";
import {getElementDimensionsById} from "@/master/utils";

export default {
    name: "SendInvitations",
    data() {
        return {
            formErrors: {},
            centered: false,
            emails: '',
            inviting: false,
            invited: null,
            successMessage: '',
            error: null,
            headerHeight: 0
        }
    },
    methods: {
        validateForm() {
            let isValid = true;
            let errors = {};

            if (this.emails === '') {
                errors['emails'] = "Please add at least one email address.";
                isValid = false
            }

            return {
                isValid,
                errors
            }
        },

        isValid() {
            this.formErrors = {};
            const {isValid, errors} = this.validateForm();
            this.formErrors = {...errors}
            return isValid
        },

        async invite() {
            if (this.isValid()) {
                try {
                    this.inviting = true;
                    this.invited = null;
                    this.error = null

                    const payload = {
                        companyId: this.$store.getters['master/detail'].companyId,
                        emails: this.emails
                    };

                    await handleUserInvitations(payload, () => {
                        this.emails = ''
                        this.invited = true;
                    }, (error) => {
                        this.error = error
                    })

                    this.inviting = false;

                } catch (err) {
                    this.error = 'The users could not be invited. Please try again.'
                    this.inviting = false;
                }
            }
        },

        close() {
            this.$store.commit('master/setDetail', null);
            this.$store.commit('master/closeDetail');
        },

    },

    mounted() {
        const header = getElementDimensionsById("send-invitation-header")
        if (header) {
            this.headerHeight = header.height
        }
    },

    components: {
        'autralis-success-comp': SuccessBox,
        'autralis-error-comp': ErrorBox,
    }
}
</script>

<style scoped>

</style>