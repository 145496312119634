<template>
    <section :class="['absolute inset-y-0 pl-10 max-w-full flex', centered ? '' : 'right-0']">
        <div class="relative w-screen max-w-5xl">
            <div class="relative h-screen flex flex-col bg-white shadow-xl overflow-x-hidden">
                <div class="relative h-full">

                    <autralis-success-comp :show="saved" :text="successMessage"/>
                    <autralis-error-comp :show="error !== null" :text="error"/>

                    <autralis-modal-comp :modal="modal"/>
                    <autralis-info-comp :show="noChangesMessage !== null" :text="noChangesMessage"/>

                    <div class="bg-white mb-6 md:mb-0 pb-0 md:pb-6 sticky top-0 z-10">
                        <header id="add-group-header" class="space-y-1 py-2 px-6 bg-blue-700">
                            <div class="flex items-center justify-between">
                                <div class="flex space-x-4">
                                    <button @click="save" class="primary-button">
                                        <span v-if="saving" class="flex items-center">
                                            <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                                        </span>
                                        <span v-if="!saving && type === 'add'"><i class="fal fa-plus mt-1 mr-1"></i> Add</span>
                                        <span v-if="!saving && type === 'edit'"><i class="fal fa-save mt-1 mr-1"></i> Save</span>
                                    </button>
                                </div>

                                <div class="flex">

                                    <!--                                    <div v-if="type==='edit'" class="border-r border-gray-100 border-opacity-30 pr-6 mr-6 relative">-->
                                    <!--                                        <i class="fal fa-ellipsis-h-alt text-white text-lg text-white hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer"-->
                                    <!--                                           @click="menuOpen = !menuOpen;"-->
                                    <!--                                        />-->
                                    <!--                                        <div v-if="menuOpen" class="bg-white absolute right-0 rounded-md shadow-lg p-4 dropdown-nav" style="">-->

                                    <!--                                        </div>-->
                                    <!--                                    </div>-->

                                    <div class="hidden h-6 md:flex items-center mr-4">
                                        <i class="fas fa-arrows-alt-h text-white hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer mt-1" @click="centered = !centered"/>
                                    </div>
                                    <div class="h-6 flex items-center">
                                        <i class="fal fa-times text-white text-2xl hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer mt-1" @click="close"/>
                                    </div>

                                </div>


                            </div>
                        </header>
                    </div>

                    <div v-if="saving" class="absolute inset-x-0 bottom-0 bg-black bg-opacity-30 z-20" :style="{top: headerHeight + 'px'}"/>

                    <!-- Go BACK if there is old state -->
                    <div
                        v-if="canGoBack"
                        @click="onBack"
                        class="flex items-center mb-6 px-6 cursor-pointer text-gray-600 text-sm hover:text-gray-800">
                        <i class="fal fa-chevron-left text-xs mr-2" style="margin-bottom: 1px;"></i>Go back
                    </div>

                    <div class="px-6">
                        <div class="flex items-center w-full border-b border-gray-200 mb-6 pb-6">
                            <div class="pr-4 text-blue-700 text-3xl">
                                <i class="fal fa-ball-pile"></i>
                            </div>
                            <div class="pl-2">
                                <div class="text-gray-600 text-2xl font-bold">Group</div>
                                <div v-if="type === 'edit'" class="text-gray-600 text-sm">Edit or update group info by changing the info below.</div>
                                <div v-if="type === 'add'" class="text-gray-600 text-sm">Fill the info below and click on save to add new group.</div>
                            </div>
                        </div>
                    </div>

                    <autralis-loading-comp v-if="fetching" size="md"/>

                    <div v-else class="flex flex-col md:flex-row w-full px-6 overflow-y-scroll pb-6">
                        <div class="w-full md:w-1/2">
                            <div class="flex flex-col items-center">

                                <div class="w-full">

                                    <div class="w-full mb-4">
                                        <autralis-input-group-comp label="Name"
                                                                   :error="formErrors.name"
                                                                   :value="group.name"
                                                                   required
                                                                   placeholder="Group One"
                                                                   @on-change="handleChange('name', $event);"/>
                                    </div>
                                    <div class="w-full mb-4">
                                        <autralis-input-group-comp label="Capacity"
                                                                   :value="group.capacity"
                                                                   placeholder="2000"
                                                                   @on-change="handleChange('capacity', $event)"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col justify-start w-full md:w-1/2 md:pl-6 mt-4 md:mt-0 md:ml-6 md:border-l md:border-gray-100">
                            <div class="flex flex-col mb-4">
                                <selector-checkboxes :options="businessUnits.data"
                                                     label="Select business units"
                                                     :error="formErrors.businessUnits || businessUnits.error"
                                                     :loading="businessUnits.loading"
                                                     :selected-options="group.businessUnits"
                                                     @on-change="handleChange('businessUnits', $event)"
                                />
                                <div class="flex w-full justify-end mt-1">
                                    <button @click="addBusinessUnit" class="secondary-button">
                                        <span><i class="fal fa-plus mt-1 mr-1"></i> Add business unit</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {modalInfo} from "@/common/modal";
import SuccessBox from "@/components/success-box";
import ErrorBox from "@/components/error-box";
import Modal from "@/components/modal";
import InfoBox from "@/components/info-box";
import Loading from "@/components/loading";
import InputGroup from "@/components/input-group";
import Selector from "@/components/selector-checkboxes";
import {isEmpty, map} from 'lodash';
import {DETAIL_PANEL_KEYS, HOME_LIST_ITEMS_PER_PAGE, INFO_BOX_TIMEOUT} from "@/master/constants";
import {handleFetchBusinessUnits, handleFetchGroup, handleSaveGroup} from "@/master/views/Home/company/handlers";
import {getElementDimensionsById} from "@/master/utils";

const INIT_GROUP = {
    'name': '',
    'businessUnits': [],
    'capacity': ''
}

export default {
    name: "AddGroup",
    data() {
        return {
            group: INIT_GROUP,
            initGroup: INIT_GROUP,
            businessUnits: {
                loading: false,
                error: null,
                data: []
            },
            type: 'edit',
            centered: false,
            menuOpen: false,
            saving: false,
            saved: null,
            successMessage: '',
            error: null,
            modal: modalInfo(),
            formErrors: {},
            fetching: false,
            noChangesMessage: null,
            headerHeight: 0
        }
    },

    computed: {
        saveAllowed() {
            let allowed = true;
            if (this.type === 'edit') {
                allowed = JSON.stringify(this.group) !== JSON.stringify(this.initGroup)
            }
            return allowed
        },
        canGoBack() {
            return this.$store.getters['master/detail'].oldState && !isEmpty(this.$store.getters['master/detail'].oldState)
        }
    },

    methods: {
        onBack() {
            if (this.canGoBack) {
                const detailPayload = this.$store.getters['master/detail'].oldState
                this.$store.commit('master/setDetail', detailPayload);
                this.$store.commit('master/openDetail');
            }
        },
        close() {
            this.$store.commit('master/setDetail', null);
            this.$store.commit('master/closeDetail');
        },

        handleChange(field, value) {
            this.group = {...this.group, [field]: value}
        },

        validateForm() {
            let isValid = true;
            let errors = {};

            if (!this.group.name) {
                errors['name'] = "The name cannot be empty.";
                isValid = false;
            }

            if (this.group.businessUnits.length < 1) {
                errors['businessUnits'] = "Please choose at least one business unit.";
                isValid = false
            }

            return {
                isValid,
                errors
            }
        },

        isValid() {
            this.formErrors = {};
            const {isValid, errors} = this.validateForm();
            this.formErrors = {...errors}
            return isValid
        },


        addBusinessUnit() {
            let oldState = {...this.$store.getters['master/detail']};
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_BUSINESS_UNIT,
                companyId: this.$store.getters['master/detail'].companyId,
                page: 1,
                itemsPerPage: HOME_LIST_ITEMS_PER_PAGE,
                oldState
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        setGroup() {
            const id = this.$store.getters['master/detail'].groupId;

            if (id) {
                this.fetchGroup(id);
            } else {
                this.type = 'add'
            }
        },

        async save() {
            if (this.isValid()) {
                if (this.saveAllowed) {
                    const payload = {
                        groupId: this.$store.getters['master/detail'].groupId,
                        // companyId: this.$store.getters['master/detail'].companyId,
                        group: this.group
                    }

                    this.saving = true;
                    this.error = null;

                    await handleSaveGroup(payload, (response) => {
                        this.$store.commit('master/setTotalBusinessUnitGroups', response.totalCount)
                        this.$store.commit('master/setBusinessUnitGroups', response.groups)
                        this.group = INIT_GROUP;
                        this.initGroup = INIT_GROUP;
                        // if (!this.canGoBack) {
                        //     // If there is no oldState close details box
                        //     setTimeout(() => this.close(), INFO_BOX_TIMEOUT)
                        // } else {
                        //     // Go to the previous state
                        //     setTimeout(() => this.onBack(), INFO_BOX_TIMEOUT)
                        // }

                        this.successMessage = 'The changes saved successfully.'
                        this.saved = true;
                    }, (error) => {
                        this.error = error
                    })

                    this.saving = false;
                } else {
                    this.noChangesMessage = 'Nothing changed.';
                    setTimeout(() => this.noChangesMessage = null, INFO_BOX_TIMEOUT)
                }
            }
        },

        async fetchBusinessUnits() {
            const payload = {
                companyId: this.$store.getters['master/detail'].companyId
            }

            this.businessUnits.loading = true;
            this.businessUnits.error = null;

            await handleFetchBusinessUnits(payload, (response) => {
                const {businessUnits} = response;
                this.businessUnits.data = map(businessUnits, businessUnit => ({id: businessUnit.id, name: businessUnit.name}));

            }, (error) => {
                this.businessUnits.error = error
            })

            this.businessUnits.loading = false;
        },

        async fetchGroup(groupId) {
            const payload = {
                groupId: groupId,
                companyId: this.$store.getters['master/detail'].companyId
            }

            this.fetching = true;
            this.error = null;

            await handleFetchGroup(payload, (response) => {
                const {group} = response;

                const mappedGroup = {
                    ...group,
                    businessUnits: map(group.businessUnits, businessUnit => businessUnit.id)
                }

                this.group = mappedGroup;
                this.initGroup = mappedGroup;

            }, (error) => {
                this.error = error
            })

            this.fetching = false;
        }
    },

    components: {
        'autralis-success-comp': SuccessBox,
        'autralis-error-comp': ErrorBox,
        'autralis-modal-comp': Modal,
        'autralis-info-comp': InfoBox,
        'autralis-loading-comp': Loading,
        'autralis-input-group-comp': InputGroup,
        'selector-checkboxes': Selector
    },

    created() {
        this.setGroup();
        this.fetchBusinessUnits();
    },

    mounted() {
        const header = getElementDimensionsById("add-group-header")
        if (header) {
            this.headerHeight = header.height
        }
    }
}
</script>

<style scoped>

</style>