<template>
    <section :class="['absolute inset-y-0 pl-10 max-w-full flex', centered ? '' : 'right-0']">
        <div class="w-screen max-w-5xl">
            <div class="h-screen flex flex-col bg-white shadow-xl overflow-x-hidden">
                <div class="relative h-full">

                    <autralis-success-comp :show="saved" :text="successMessage"/>
                    <autralis-error-comp :show="error !== null" :text="error"/>

                    <autralis-modal-comp :modal="modal"/>
                    <autralis-info-comp :show="noChangesMessage !== null" :text="noChangesMessage"/>

                    <div id="add-business-unit-header" class="bg-white mb-6 md:mb-0 pb-0 md:pb-6 sticky top-0 z-10">
                        <header class="space-y-1 py-2 px-6 bg-blue-700 ">
                            <div class="flex items-center justify-between">
                                <div class="flex space-x-4">
                                    <button @click="save" class="primary-button">
                                        <span v-if="saving" class="flex items-center">
                                            <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                                        </span>
                                        <span v-if="!saving && type === 'add'"><i class="fal fa-plus mt-1 mr-1"></i> Add</span>
                                        <span v-if="!saving && type === 'edit'"><i class="fal fa-save mt-1 mr-1"></i> Save</span>
                                    </button>
                                </div>

                                <div class="flex">

                                    <div v-if="type==='edit'" class="border-r border-gray-100 border-opacity-30 pr-6 mr-6 relative">
                                        <i class="fal fa-ellipsis-h-alt text-white text-lg text-white hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer"
                                           @click="menuOpen = !menuOpen;"
                                        />
                                        <div v-if="menuOpen" class="bg-white absolute right-0 rounded-md shadow-lg p-4 dropdown-nav" style="">

                                        </div>
                                    </div>

                                    <div class="hidden h-6 md:flex items-center mr-4">
                                        <i class="fas fa-arrows-alt-h text-white hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer mt-1" @click="centered = !centered"/>
                                    </div>
                                    <div class="h-6 flex items-center">
                                        <i class="fal fa-times text-white text-2xl hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer mt-1" @click="close"/>
                                    </div>

                                </div>


                            </div>
                        </header>
                    </div>

                    <div v-if="saving" class="absolute inset-x-0 bottom-0 bg-black bg-opacity-30 z-20" :style="{top: headerHeight + 'px'}"/>

                    <!-- Go BACK if there is old state -->
                    <div
                        v-if="canGoBack"
                        @click="onBack"
                        class="flex items-center mb-6 px-6 cursor-pointer text-gray-600 text-sm hover:text-gray-800">
                        <i class="fal fa-chevron-left text-xs mr-2" style="margin-bottom: 1px;"></i>Go back
                    </div>

                    <div class="px-6">
                        <div class="flex items-center w-full border-b border-gray-200 mb-6 pb-6">
                            <div class="pr-4 text-blue-700 text-3xl">
                                <i class="fal fa-sitemap"></i>
                            </div>
                            <div class="pl-2">
                                <div class="text-gray-600 text-2xl font-bold">Business unit</div>
                                <div v-if="type === 'edit'" class="text-gray-600 text-sm">Edit or update the business unit info by changing the info below.</div>
                                <div v-if="type === 'add'" class="text-gray-600 text-sm">Fill the info below and click on save to add new business unit.</div>
                            </div>
                        </div>
                    </div>

                    <autralis-loading-comp v-if="fetching" size="md"/>

                    <div v-else class="flex flex-col md:flex-row w-full px-6 overflow-y-scroll pb-6">
                        <div class="w-full md:w-1/2 md:pr-6 md:mr-6 md:border-r md:border-gray-100" style="height: fit-content;">
                            <div class="flex flex-col items-center">

                                <div class="w-full">

                                    <div class="w-full mb-4">
                                        <autralis-input-group-comp label="Name"
                                                                   :error="formErrors.name"
                                                                   :value="businessUnit.name"
                                                                   required
                                                                   placeholder="Autralis"
                                                                   @on-change="handleBusinessUnitChange('name', $event);"/>
                                    </div>
                                    <div class="w-full mb-4">
                                        <autralis-input-group-comp label="Subtitle"
                                                                   :error="formErrors.subTitle"
                                                                   :value="businessUnitExtra.subTitle"
                                                                   placeholder="Volkswagen, SEAT, SKODA, ..."
                                                                   @on-change="handleBusinessUnitExtraChange('subTitle', $event)"/>
                                    </div>

                                    <div class="w-full mb-4">
                                        <autralis-input-group-comp label="Email"
                                                                   :error="formErrors.email"
                                                                   :value="businessUnit.email"
                                                                   required
                                                                   placeholder="info@company.com"
                                                                   @on-change="handleBusinessUnitChange('email', $event)"/>

                                    </div>

                                    <div class="flex flex-col mb-4 w-full">
                                        <autralis-select-multiple-comp :options="addresses.data"
                                                                       :selectedOptions="businessUnit.address"
                                                                       :onlyOneSelection="true"
                                                                       :error="formErrors.address || addresses.error"
                                                                       :loading="addresses.loading"
                                                                       required
                                                                       @on-change="handleBusinessUnitChange('address', $event)"
                                                                       placeholder="Select address"
                                                                       label="Address"/>
                                        <!--                                        <div class="flex w-full justify-end mt-1">-->
                                        <!--                                            <button @click="addAddress" class="secondary-button">-->
                                        <!--                                                <span><i class="fal fa-plus mt-1 mr-1"></i> Add address</span>-->
                                        <!--                                            </button>-->
                                        <!--                                        </div>-->
                                    </div>

                                    <div class="flex space-x-2 mb-4">
                                        <div class="w-1/2">
                                            <autralis-input-group-comp label="Vat number"
                                                                       :error="formErrors.vatNumber"
                                                                       :value="businessUnit.vatNumber"
                                                                       required
                                                                       placeholder="BE0123.456.111"
                                                                       @on-change="handleBusinessUnitChange('vatNumber', $event)"/>
                                        </div>
                                        <div class="w-1/2">
                                            <autralis-input-group-comp label="Telephone"
                                                                       :error="formErrors.telephone"
                                                                       :value="businessUnit.telephone"
                                                                       required
                                                                       placeholder="+32 9 230 02 33"
                                                                       @on-change="handleBusinessUnitChange('telephone', $event)"/>
                                        </div>
                                    </div>

                                    <div class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2 mb-4">
                                        <div class="w-full md:w-1/2">
                                            <autralis-input-group-comp label="Url"
                                                                       :error="formErrors.url"
                                                                       :value="businessUnit.url"
                                                                       placeholder="www.company.com"
                                                                       @on-change="handleBusinessUnitChange('url', $event)"/>
                                        </div>
                                        <div class="flex space-x-2 w-full md:w-1/2">
                                            <autralis-input-group-comp label="Reference"
                                                                       type="number"
                                                                       :value="businessUnit.reference"
                                                                       placeholder="0"
                                                                       @on-change="handleBusinessUnitChange('reference', $event)"/>
                                        </div>
                                    </div>

                                    <div class="flex space-x-2 mb-4">
                                        <div class="w-1/2">
                                            <autralis-input-group-comp label="Longitude"
                                                                       :value="businessUnitExtra.longitude"
                                                                       placeholder="5.204011"
                                                                       @on-change="handleBusinessUnitExtraChange('longitude', $event)"/>
                                        </div>
                                        <div class="w-1/2">
                                            <autralis-input-group-comp label="Latitude"
                                                                       :value="businessUnitExtra.latitude"
                                                                       placeholder="50.800354"
                                                                       @on-change="handleBusinessUnitExtraChange('latitude', $event)"/>
                                        </div>
                                    </div>

                                    <div class="w-full mb-4">
                                        <autralis-input-group-comp label="Appointment url"
                                                                   :value="businessUnitExtra.appointmentUrl"
                                                                   placeholder="https://outlook.office.365.com/url"
                                                                   @on-change="handleBusinessUnitExtraChange('appointmentUrl', $event)"/>
                                    </div>

                                    <div class="w-full">
                                        <autralis-input-group-comp label="Video call url"
                                                                   :value="businessUnitExtra.videoCallUrl"
                                                                   placeholder="https://outlook.office.365.com/url"
                                                                   @on-change="handleBusinessUnitExtraChange('videoCallUrl', $event)"/>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col justify-start w-full md:w-1/2 mt-4 md:mt-0" style="height: fit-content;">
                            <div class="flex flex-col mb-4">
                                <autralis-select-multiple-comp :options="managers.data"
                                                               :selectedOptions="businessUnit.manager"
                                                               :onlyOneSelection="true"
                                                               :loading="managers.loading"
                                                               :error="managers.error"
                                                               @on-change="handleBusinessUnitChange('manager', $event)"
                                                               placeholder="Select manager"
                                                               label="Manager"/>
                                <!--                                <div class="flex w-full justify-end mt-1">-->
                                <!--                                    <button @click="addManager" class="secondary-button">-->
                                <!--                                        <span><i class="fal fa-plus mt-1 mr-1"></i> Add manager</span>-->
                                <!--                                    </button>-->
                                <!--                                </div>-->
                            </div>

                            <div class="mb-4">
                                <selector-checkboxes :options="brands.data"
                                                     label="Select brands"
                                                     :error="formErrors.brands || brands.error"
                                                     :loading="brands.loading"
                                                     :selected-options="businessUnit.brands"
                                                     @on-change="handleBusinessUnitChange('brands', $event)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SuccessBox from "@/components/success-box";
import InputGroup from "@/components/input-group";
import ErrorBox from "@/components/error-box";
import Modal from "@/components/modal";
// import BaseSelect from "@/components/base-select";

import {modalInfo} from "@/common/modal";
import {head, isEmpty, map} from "lodash";
import {handleFetchAddresses, handleFetchBrands, handleFetchBusinessUnit, handleFetchCompanyManagers, handleSaveBusinessUnit} from "../handlers";
import {DETAIL_PANEL_KEYS, HOME_LIST_ITEMS_PER_PAGE, INFO_BOX_TIMEOUT} from "@/master/constants";
import InfoBox from "@/components/info-box";
import Loading from "@/components/loading";
import {isValidEmail} from "@/common";
import SelectMultiple from "@/components/select-multiple";
import Selector from "@/components/selector-checkboxes";
import {getElementDimensionsById} from "@/master/utils";


const INIT_BUSINESS_UNIT = {
    id: '',
    name: '',
    address: [],
    brands: [],
    groups: [],
    vatNumber: '',
    telephone: '',
    email: '',
    reference: '',
    customerReference: '',
    url: '',
    manager: []
}

const INIT_BUSINESS_UNIT_EXTRA = {
    id: '',
    longitude: '',
    latitude: '',
    name: '',
    address: '',
    postal: '',
    city: '',
    email: '',
    telephone: '',
    subTitle: '',
    appointmentUrl: '',
    videoCallUrl: ''
}

export default {
    name: "AddBusinessUnit",
    data() {
        return {
            businessUnit: INIT_BUSINESS_UNIT,
            initBusinessUnit: INIT_BUSINESS_UNIT,
            businessUnitExtra: INIT_BUSINESS_UNIT_EXTRA,
            initBusinessUnitExtra: INIT_BUSINESS_UNIT_EXTRA,

            brands: {
                loading: false,
                error: null,
                data: []
            },
            addresses: {
                loading: false,
                error: null,
                data: []
            },
            managers: {
                loading: false,
                error: null,
                data: []
            },

            type: 'edit',
            centered: false,
            menuOpen: false,
            saved: null,
            successMessage: '',
            error: null,
            modal: modalInfo(),
            formErrors: {},
            saving: false,
            fetching: false,
            noChangesMessage: null,
            headerHeight: 0
        }
    },

    computed: {
        saveAllowed() {
            let allowed = true;
            if (this.type === 'edit') {
                allowed = JSON.stringify(this.businessUnit) !== JSON.stringify(this.initBusinessUnit)
            }
            return allowed;
        },
        canGoBack() {
            return this.$store.getters['master/detail'].oldState && !isEmpty(this.$store.getters['master/detail'].oldState)
        }
    },

    methods: {
        onBack() {
            if (this.canGoBack) {
                const detailPayload = this.$store.getters['master/detail'].oldState
                this.$store.commit('master/setDetail', detailPayload);
                this.$store.commit('master/openDetail');
            }
        },
        close() {
            this.$store.commit('master/setDetail', null);
            this.$store.commit('master/closeDetail');
        },

        handleBusinessUnitChange(field, value) {
            this.businessUnit = {...this.businessUnit, [field]: value}
        },

        handleBusinessUnitExtraChange(field, value) {
            this.businessUnitExtra = {...this.businessUnitExtra, [field]: value}
        },

        addNew(key) {
            let oldState = {...this.$store.getters['master/detail']};

            const detailPayload = {
                key: key,
                companyId: this.$store.getters['master/detail'].companyId,
                page: 1,
                itemsPerPage: HOME_LIST_ITEMS_PER_PAGE,
                oldState
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        addAddress() {
            this.addNew(DETAIL_PANEL_KEYS.EDIT_COMPANY_ADDRESS);
        },

        addManager() {
            this.addNew(DETAIL_PANEL_KEYS.EDIT_COMPANY_USER);
        },

        addGroup() {
            this.addNew(DETAIL_PANEL_KEYS.EDIT_GROUP);
        },

        editGroup(group) {
            let oldState = {...this.$store.getters['master/detail']};
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_GROUP,
                companyId: this.$store.getters['master/detail'].companyId,
                groupId: group.id,
                page: 1,
                itemsPerPage: HOME_LIST_ITEMS_PER_PAGE,
                oldState
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        changed(msg) {
            this.saved = true;
            setTimeout(() => this.saved = null, INFO_BOX_TIMEOUT)
            this.successMessage = msg;
            this.menuOpen = false
        },

        setBusinessUnit() {
            const id = this.$store.getters['master/detail'].businessUnitId;

            if (id) {
                this.fetchBusinessUnit(id);
            } else {
                this.type = 'add'
            }
        },

        setStoreBusinessUnits(businessUnits) {
            this.$store.commit('master/setBusinessUnits', businessUnits)
        },

        validateForm() {
            let isValid = true;
            let errors = {};

            if (!this.businessUnit.name) {
                errors['name'] = "The name cannot be empty.";
                isValid = false;
            }

            if (!isValidEmail(this.businessUnit.email)) {
                errors['email'] = "The format of the email address is not correct.";
                isValid = false
            }

            if (!this.businessUnit.vatNumber) {
                errors['vatNumber'] = "The VAT number cannot be empty.";
                isValid = false
            }

            if (!this.businessUnit.telephone) {
                errors['telephone'] = "The telephone cannot be empty.";
                isValid = false
            }


            if (this.businessUnit.address.length < 1) {
                errors['address'] = "The address cannot be empty.";
                isValid = false
            }


            /*if (!this.businessUnit.reference) {
                errors['reference'] = "The reference cannot be empty.";
                isValid = false
            }*/

            if (this.businessUnit.groups.length < 1) {
                errors['groups'] = "Please choose at least one group.";
                isValid = false
            }

            if (this.businessUnit.brands.length < 1) {
                errors['brands'] = "Please choose at least one brand.";
                isValid = false
            }


            return {
                isValid,
                errors
            }
        },

        isValid() {
            this.formErrors = {};
            const {isValid, errors} = this.validateForm();
            this.formErrors = {...errors}
            return isValid
        },

        async fetchBusinessUnit(businessUnitId) {

            const payload = {
                businessUnitId: businessUnitId,
                companyId: this.$store.getters['master/detail'].companyId
            }

            this.fetching = true;
            this.error = null;

            await handleFetchBusinessUnit(payload, (response) => {
                const {businessUnit, businessUnitExtra} = response;

                const bu = {
                    ...businessUnit,
                    manager: [{value: businessUnit.manager.userId, text: businessUnit.manager.firstName + ' ' + businessUnit.manager.lastName}],
                    address: [{value: businessUnit.address.id, text: businessUnit.address.address + ', ' + businessUnit.address.postalCode + ' ' + businessUnit.address.city}],
                    groups: map(businessUnit.groups, group => group.id),
                    brands: map(businessUnit.brands, brand => brand.id),
                }

                this.businessUnit = bu;
                this.initBusinessUnit = bu;
                this.businessUnitExtra = {...businessUnitExtra}
                this.initBusinessUnitExtra = {...businessUnitExtra};

            }, (error) => {
                this.error = error
            })

            this.fetching = false;
        },

        async save() {
            if (this.isValid()) {
                if (this.saveAllowed) {
                    const payload = {
                        businessUnitId: this.$store.getters['master/detail'].businessUnitId,
                        companyId: this.$store.getters['master/detail'].companyId,
                        page: this.$store.getters['master/detail'].page,
                        itemsPerPage: this.$store.getters['master/detail'].itemsPerPage,
                        businessUnit: {
                            ...this.businessUnit,
                            manager: head(this.businessUnit.manager).value,
                            address: head(this.businessUnit.address).value,
                            extra: this.businessUnitExtra
                        }
                    }

                    this.saving = true;
                    this.error = null;

                    await handleSaveBusinessUnit(payload, (response) => {
                        this.setStoreBusinessUnits(response.businessUnits)

                        this.businessUnit = INIT_BUSINESS_UNIT;
                        this.initBusinessUnit = INIT_BUSINESS_UNIT;
                        this.businessUnitExtra = INIT_BUSINESS_UNIT_EXTRA;
                        this.initBusinessUnitExtra = INIT_BUSINESS_UNIT_EXTRA;
                        // if (!this.canGoBack) {
                        //     // If there is no oldState close details box
                        //     setTimeout(() => this.close(), INFO_BOX_TIMEOUT)
                        // } else {
                        //     // Go to the previous state
                        //     setTimeout(() => this.onBack(), INFO_BOX_TIMEOUT)
                        // }

                        this.successMessage = 'The changes saved successfully.'
                        this.saved = true;
                    }, (error) => {
                        this.error = error
                    })

                    this.saving = false;
                } else {
                    this.noChangesMessage = 'Nothing changed.';
                    setTimeout(() => this.noChangesMessage = null, INFO_BOX_TIMEOUT)
                }
            }
        },

        async fetchBrands() {
            const payload = {
                companyId: this.$store.getters['master/detail'].companyId
            }

            this.brands.loading = true;
            this.brands.error = null;

            await handleFetchBrands(payload, (response) => {
                const {brands} = response;
                this.brands.data = brands;

            }, (error) => {
                this.brands.error = error
            })

            this.brands.loading = false;
        },

        async fetchAddresses() {
            const payload = {
                companyId: this.$store.getters['master/detail'].companyId
            }

            this.addresses.loading = true;
            this.addresses.error = null;

            await handleFetchAddresses(payload, (response) => {
                const {addresses} = response;
                this.addresses.data = map(addresses, address => ({value: address.addressId, text: address.address + ', ' + address.postalCode + ' ' + address.city}));

            }, (error) => {
                this.addresses.error = error
            })

            this.addresses.loading = false;
        },

        async fetchManagers() {
            const payload = {
                companyId: this.$store.getters['master/detail'].companyId
            }

            this.managers.loading = true;
            this.managers.error = null;

            await handleFetchCompanyManagers(payload, (response) => {
                const {managers} = response;
                this.managers.data = map(managers, manager => ({value: manager.id, text: manager.firstName + ' ' + manager.lastName}))

            }, (error) => {
                this.managers.error = error
            })

            this.managers.loading = false;
        },
    },

    created() {
        this.setBusinessUnit();
        this.fetchBrands()
        this.fetchAddresses()
        this.fetchManagers()
    },

    mounted() {
        const header = getElementDimensionsById("add-business-unit-header")
        if (header) {
            this.headerHeight = header.height
        }
    },

    components: {
        'autralis-success-comp': SuccessBox,
        'autralis-error-comp': ErrorBox,
        'autralis-modal-comp': Modal,
        'autralis-input-group-comp': InputGroup,
        // 'autralis-base-select-comp': BaseSelect,
        'autralis-select-multiple-comp': SelectMultiple,
        'selector-checkboxes': Selector,
        'autralis-info-comp': InfoBox,
        'autralis-loading-comp': Loading,
    }
}
</script>

<style scoped>
.dropdown-nav {
    top: 100%;
    min-width: 150px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

</style>