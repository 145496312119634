<template>
    <section :class="['absolute inset-y-0 pl-10 max-w-full flex', centered ? '' : 'right-0']">
        <div class="w-screen max-w-5xl">
            <div class="h-screen flex flex-col bg-white shadow-xl overflow-hidden">
                <div class="relative h-full">


                    <autralis-success-comp :show="saved" :text="successMessage"/>
                    <autralis-error-comp :show="error !== null" :text="error"/>

                    <autralis-modal-comp :modal="modal"/>
                    <autralis-info-comp :show="noChangesMessage !== null" :text="noChangesMessage"/>


                    <header id="add-address-header" class="space-y-1 py-2 px-6 bg-blue-700" style="height: 50px">
                        <div class="flex items-center justify-between">
                            <div class="flex space-x-4">
                                <button @click="save"
                                        class="primary-button ">
                                    <span v-if="saving" class="flex items-center">
                                        <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                                    </span>
                                    <span v-if="!saving && type === 'add'"><i class="fal fa-plus mt-1 mr-1"></i> Add</span>
                                    <span v-if="!saving && type === 'edit'"><i class="fal fa-save mt-1 mr-1"></i> Save</span>

                                </button>

                                <div v-if="type === 'edit' && this.address.default === false"
                                     class="hidden md:flex items-center sm:text-sm sm:leading-5 md:border-l border-gray-100 border-opacity-30 pl-6 ml-6">
                                    <button @click="confirmDefault"
                                            class="primary-button">
                                        <span v-if="setDefaultLoading" class="flex items-center">
                                            <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                                        </span>
                                        <span v-else><i class="fal fa-check mt-1 mr-1"></i> Make default</span>

                                    </button>
                                </div>
                            </div>
                            <div class="flex">
                                <div v-if="type === 'edit' && !isDefault" class="border-r border-gray-100 border-opacity-30 pr-6 mr-6 relative">
                                    <i class="fal fa-ellipsis-h-alt text-white text-lg text-white hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer"
                                       @click="toggleMenu"/>
                                    <div v-if="menuOpen" class="bg-white absolute right-0 rounded-md shadow-lg p-4" style="top: 100%;min-width: 150px;">
                                        <div v-if="!isDefault" class="block md:hidden bg-white cursor-pointer select-none mb-2 text-gray-700" @click="confirmDefault">
                                            <div v-if="setDefaultLoading" class="flex items-center">
                                                <span class="fas fa-spinner-third fa-spin mr-2"></span>
                                                <translate>Saving</translate>
                                                ...
                                            </div>
                                            <div v-else><i class="fal fa-check mt-1 mr-1"></i> Make default</div>
                                        </div>

                                        <div v-if="!isDefault" class="bg-white text-red-500 hover:text-red-800 cursor-pointer select-none" @click="confirmDelete">
                                            <div v-if="deleting" class="flex items-center">
                                                <span class="fas fa-spinner-third fa-spin mr-2"></span>
                                                <translate>Deleting</translate>
                                                ...
                                            </div>
                                            <div v-else class="flex items-center">
                                                <i class="fal fa-trash mr-2"/> Delete
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div class="hidden h-6 lg:flex items-center mr-4">
                                    <i class="fas fa-arrows-alt-h text-white hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer mt-1" @click="centered = !centered"/>
                                </div>
                                <div class="h-6 flex items-center">
                                    <i class="fal fa-times text-white text-2xl text-white hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer mt-1" @click="close"/>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div v-if="saving" class="absolute inset-x-0 bottom-0 bg-black bg-opacity-30 z-20" :style="{top: headerHeight + 'px'}"/>

                    <!-- Go BACK if there is old state -->
                    <div v-if="canGoBack"
                         @click="onBack"
                         class="flex items-center mt-6 px-6 cursor-pointer text-gray-600 text-sm hover:text-gray-800">
                        <i class="fal fa-chevron-left text-xs mr-2" style="margin-bottom: 1px;"></i>Go back
                    </div>

                    <div class="mt-6 px-6">
                        <div v-if="isDefault" class="text-center bg-green-100 text-green-900 mb-6 rounded-md py-2 text-sm">This is a <span class="font-semibold">default</span> address.</div>
                        <div class="flex items-center w-full border-b border-gray-200 mb-6 pb-6">
                            <div class="pr-4 text-blue-700 text-3xl">
                                <i class="fal fa-map-marker-alt"/>
                            </div>
                            <div class="pl-2">
                                <div class="text-gray-600 text-2xl font-bold">Address</div>
                                <div v-if="type === 'edit'" class="text-gray-600 text-sm">Edit or update the address info.</div>
                                <div v-if="type === 'add'" class="text-gray-600 text-sm">Fill the info below and click on save to add new company address.</div>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col md:flex-row w-full px-6">
                        <div class="w-full xl:w-3/4 2xl:w-2/3 pr-0 lg:pr-6">
                            <div class="flex flex-col items-center">
                                <div class="w-full">
                                    <div class="block text-sm font-medium mb-1 text-gray-700">Address</div>

                                    <div class="flex items-center w-full border shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md px-3 border-gray-300 mb-6">
                                        <i class="fas fa-search text-gray-400 cursor-pointer mr-3" style="font-size: 18px;"/>
                                        <input v-model="searchAddress" id="address-autocomplete" type="text" class="border-0 w-full px-0 focus:outline-none focus:ring-0 placeholder-gray-300"
                                               placeholder="Search address">
                                    </div>


                                    <autralis-input-group-comp :error="formErrors.address"
                                                               :value="address.address"
                                                               required
                                                               placeholder="Hundelgemsesteenweg 575"
                                                               @on-change="handleChange('address', $event)"/>

                                    <div class="flex flex-row mt-4">
                                        <div class="w-1 w-1/4">
                                            <autralis-input-group-comp :error="formErrors.postalCode"
                                                                       :value="address.postalCode"
                                                                       required
                                                                       placeholder="9820"
                                                                       @on-change="handleChange('postalCode', $event)"/>
                                        </div>
                                        <div class="ml-2 w-3/4">
                                            <autralis-input-group-comp :error="formErrors.city"
                                                                       :value="address.city"
                                                                       required
                                                                       placeholder="Merelbeke"
                                                                       @on-change="handleChange('city', $event)"/>
                                        </div>
                                    </div>
                                    <div class="mt-6">
                                        <autralis-countries-select-comp :selected="address.countryCode"
                                                                        @on-country-change="updateCountry"
                                                                        className="w-full"
                                                                        :error="formErrors.countryCode"
                                                                        :show-label="false"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="type === 'edit'" class="flex flex-col justify-start w-full md:w-1/2 pl-0 md:pl-6 md:border-l md:border-gray-200 mt-6 md:mt-0">
                            <div class="flex justify-between border rounded-md shadow-sm border-gray-200 w-full p-3 mt-2">
                                <div class="sm:text-sm sm:leading-5">
                                    Compound
                                    <span v-if="address.compound" class="ml-2 px-2 py-1 text-xs rounded bg-green-50 text-green-900">Active</span>
                                    <span v-if="!address.compound" class="ml-2 px-2 py-1 text-xs rounded bg-gray-100 text-gray-600">Inactive</span>
                                </div>
                                <t-toggle v-if="!setCompoundLoading" :checked="address.compound" @change="handleChangeCompound"/>
                                <span v-if="setCompoundLoading" class="flex items-center">
                                    <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import CountriesSelect from "../../../../../components/countries-select";
import InputGroup from "@/components/input-group";
import SuccessBox from "@/components/success-box";
import InfoBox from "@/components/info-box";
import ErrorBox from "@/components/error-box";
import Modal from "@/components/modal";
import {modalInfo} from "@/common/modal";
import {COUNTRY_FULL_NAME, INFO_BOX_TIMEOUT} from "../../../../constants";
import {find, isEqual, omit, forEach, isEmpty} from "lodash";
import {handleCompoundAddressChange, handleDefaultAddressChange, handleDeleteAddresses, handleSaveAddress} from "../handlers";
import {getElementDimensionsById} from "@/master/utils";

export default {
    name: "AddCompanyAddress",
    data() {
        return {
            address: {},
            initAddress: {},
            type: 'edit',
            centered: false,
            setDefaultLoading: false,
            saved: null,
            successMessage: '',
            setCompoundLoading: false,
            error: null,
            deleting: false,
            modal: modalInfo(),
            formErrors: {},
            saving: false,
            menuOpen: false,
            noChangesMessage: null,
            addressAutocomplete: null,
            searchAddress: '',
            loading: false,
            headerHeight: 0
        }
    },

    computed: {
        isDefault() {
            return this.address.default;
        },

        saveAllowed() {
            let hasChanged = true
            if (this.type === 'edit') {
                hasChanged = !isEqual(omit(this.address, ['default', 'compound']), omit(this.initAddress, ['default', 'compound']))
            }

            return hasChanged
        },

        canGoBack() {
            return this.$store.getters['master/detail'].oldState && !isEmpty(this.$store.getters['master/detail'].oldState)
        }
    },

    methods: {
        onBack() {
            if (this.canGoBack) {
                const detailPayload = this.$store.getters['master/detail'].oldState
                this.$store.commit('master/setDetail', detailPayload);
                this.$store.commit('master/openDetail');
            }
        },

        handleChange(field, value) {
            this.address = {...this.address, [field]: value}
        },

        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },

        close() {
            this.$store.commit('master/setDetail', null);
            this.$store.commit('master/closeDetail');
        },

        toggleCenter() {
            this.centered = !this.centered;
        },
        updateCountry(country) {
            this.address = {...this.address, countryCode: country}
        },

        country(code) {
            return COUNTRY_FULL_NAME(code)
        },

        setAddress() {
            const id = this.$store.getters['master/detail'].addressId;
            if (id) {
                this.fetchAddress(id);
            } else {
                this.type = 'add'
            }
        },

        setStoreAddresses(addresses) {
            this.$store.commit('master/setCompanyAddresses', addresses)
        },

        async fetchAddress(id) {
            const address = find(this.$store.getters['master/companyAddresses'], item => item.id === id);
            if (address) {
                this.address = {...address};
                this.initAddress = {...address};
            }
        },

        async save() {
            if (this.isValid()) {
                if (this.saveAllowed) {
                    const payload = {
                        addressId: this.address.addressId ? this.address.addressId : 0,
                        companyId: this.$store.getters['master/detail'].companyId,
                        page: this.$store.getters['master/detail'].page,
                        itemsPerPage: this.$store.getters['master/detail'].itemsPerPage,
                        address: this.address
                    }

                    this.saving = true;
                    this.error = null;

                    await handleSaveAddress(payload, (response) => {
                        this.setStoreAddresses(response.addresses)
                        if (this.type !== 'edit') {
                            this.address = {}
                            this.initAddress = {}
                            if (!this.canGoBack) {
                                // If there is no oldState close details box
                                setTimeout(() => this.close(), INFO_BOX_TIMEOUT)
                            } else {
                                // Go to the previous state
                                setTimeout(() => this.onBack(), INFO_BOX_TIMEOUT)
                            }
                        }
                        this.successMessage = 'The address successfully saved.'
                        this.saved = true;
                    }, (error) => {
                        this.error = error
                    })

                    this.saving = false;
                } else {
                    this.noChangesMessage = 'Nothing changed.';
                    setTimeout(() => this.noChangesMessage = null, INFO_BOX_TIMEOUT)
                }
            }
        },

        confirmDelete() {
            this.modal.view('Delete address', 'Are you sure that you want to delete ' + this.address.address + '?', 'Confirm', 'exclamation-triangle', this.handleDelete)
        },

        confirmDefault() {
            this.modal.view('Change to default', 'Are you sure that you want change ' + this.address.address + ' to a default address?', 'Confirm', 'exclamation-triangle',
                this.handleChangeDefault)
        },

        validateForm() {
            let isValid = true;
            let errors = {};

            if (!this.address.address) {
                errors['address'] = "The address cannot be empty.";
                isValid = false;
            }
            if (!this.address.postalCode) {
                errors['postalCode'] = "The postal code cannot be empty.";
                isValid = false
            }

            if (!this.address.city) {
                errors['city'] = "The city cannot be empty.";
                isValid = false
            }

            if (!this.address.countryCode) {
                errors['countryCode'] = "The country cannot be empty.";
                isValid = false
            }

            return {
                isValid,
                errors
            }
        },

        isValid() {
            this.formErrors = {};
            const {isValid, errors} = this.validateForm();
            this.formErrors = {...errors}
            return isValid
        },

        async handleDelete() {
            try {
                this.deleting = true;
                this.saved = null;
                this.successMessage = ''
                this.error = null

                const payload = {
                    data: [this.address.id],
                    page: this.$store.getters['master/detail'].page,
                    recordsPerPage: this.$store.getters['master/detail'].itemsPerPage
                };
                await handleDeleteAddresses(payload, (response) => {
                    this.setStoreAddresses(response.addresses)
                    this.close(); // Close detail panel
                    this.successMessage = 'The address successfully deleted.'
                    this.saved = true;
                }, (error) => {
                    this.error = error
                })

                this.deleting = false;

            } catch (err) {
                this.error = 'The company address could not be deleted. Please try again.'
                this.deleting = false;
            }
        },

        async handleChangeDefault(value) {
            if (this.type === 'add') {
                this.address = {...this.address, default: value}
            } else {
                const addressId = this.address.id
                if (!this.isDefault && addressId) {
                    try {
                        this.setDefaultLoading = true;
                        this.saved = null;
                        this.successMessage = ''
                        this.error = null

                        const payload = {
                            companyId: this.$store.getters['master/detail'].companyId,
                            addressId: addressId
                        };
                        await handleDefaultAddressChange(payload, (response) => {
                            this.setStoreAddresses(response.addresses)
                            this.setAddress()
                            this.successMessage = 'The address successfully changed to default.'
                            this.saved = true;
                        }, (error) => {
                            this.error = error
                        })

                        this.setDefaultLoading = false;
                    } catch (err) {
                        this.error = 'The company address could not be changed to default. Please try again.'
                        this.setDefaultLoading = false;
                    }
                }
            }
        },

        async handleChangeCompound(value) {
            if (this.type === 'add') {
                this.address = {...this.address, compound: value}
            } else {
                try {
                    this.setCompoundLoading = true;
                    this.saved = null;
                    this.successMessage = ''
                    this.error = null

                    const payload = {
                        data: [{id: this.address.id, compound: value}],
                        selected: [this.address.id]
                    }

                    await handleCompoundAddressChange(payload, (response) => {
                        this.setStoreAddresses(response.addresses)
                        this.setAddress()
                        this.successMessage = 'The address successfully changed.'
                        this.saved = true;
                    }, (error) => {
                        this.error = error
                    })

                    this.setCompoundLoading = false;
                } catch (err) {
                    this.error = 'The company address could not be changed to compound. Please try again.'
                    this.setCompoundLoading = false;
                }
            }
        },

        fillInAddress() {
            let address, houseNumber, city, postalCode, countryCode = '';

            const place = this.addressAutocomplete.getPlace();
            forEach(place.address_components, a => {
                if (a.types.includes('route')) address = a.long_name;
                if (a.types.includes('street_number')) houseNumber = a.long_name;
                if (a.types.includes('locality')) city = a.long_name;
                if (a.types.includes('postal_code')) postalCode = a.long_name;
                if (a.types.includes('country')) countryCode = a.short_name;
            });

            this.address.address = houseNumber ? address + ' ' + houseNumber : address;
            this.address.city = city
            this.address.postalCode = postalCode
            this.address.countryCode = countryCode
            this.searchAddress = '';
        },
    },

    created() {
        this.setAddress();
    },

    mounted() {
        this.addressAutocomplete = new window.google.maps.places.Autocomplete(document.getElementById("address-autocomplete"), {types: ["geocode"]});
        this.addressAutocomplete.addListener("place_changed", this.fillInAddress);
        setTimeout(function () {
            window.$("input").attr("autocomplete", "none");
        }, 400);

        const header = getElementDimensionsById("add-address-header")
        if (header) {
            this.headerHeight = header.height
        }
    },

    components: {
        'autralis-countries-select-comp': CountriesSelect,
        'autralis-input-group-comp': InputGroup,
        'autralis-success-comp': SuccessBox,
        'autralis-info-comp': InfoBox,
        'autralis-error-comp': ErrorBox,
        'autralis-modal-comp': Modal
    }
}
</script>

<style scoped>

</style>