<template>
    <section :class="['absolute inset-y-0 pl-10 max-w-full flex', centered ? '' : 'right-0']">
        <div class="w-screen max-w-5xl">
            <div class="h-screen flex flex-col bg-white shadow-xl overflow-x-hidden">
                <autralis-success-comp :show="sent" text="Message sent successfully."/>
                <autralis-error-comp :show="sendError !== null" :text="sendError"/>


                <div class="bg-white pb-6 sticky top-0 z-10">
                    <header class="space-y-1 py-2 px-6 bg-blue-700 ">
                        <div class="flex items-center justify-between">
                            <div class="flex space-x-4">
                                <button @click="sendMessage" class="primary-button">
                                                                        <span v-if="sending" class="flex items-center">
                                                                            <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Sending</translate> ...
                                                                        </span>
                                    <span v-else><i class="fal fa-paper-plane mt-1 mr-1"></i> Send</span>
                                </button>
                            </div>

                            <div class="flex">
                                <div class="hidden h-6 md:flex items-center mr-4">
                                    <i class="fas fa-arrows-alt-h text-white hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer mt-1" @click="centered = !centered"/>
                                </div>
                                <div class="h-6 flex items-center">
                                    <i class="fal fa-times text-white text-2xl hover:text-gray-300 transition ease-in-out duration-150 cursor-pointer mt-1" @click="close"/>
                                </div>

                            </div>


                        </div>
                    </header>
                </div>

                <div class="px-6">
                    <div class="flex items-center w-full border-b border-gray-200 mb-6 pb-6">
                        <div class="pr-4 text-blue-700 text-3xl">
                            <i class="fal fa-paper-plane"></i>
                        </div>
                        <div class="pl-2">
                            <div class="text-gray-600 text-2xl font-bold">Send new message</div>
                            <div class="text-gray-600 text-sm">Choose users from the list below and send them a message</div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col md:flex-row w-full px-6 h-full">
                    <div class="w-full">

                        <div class="w-full mb-4">
                            <autralis-input-group-comp label="Subject"
                                                       :error="formErrors.subject"
                                                       :value="subject"
                                                       required
                                                       placeholder="Message subject"
                                                       @on-change="subject = $event"/>

                        </div>

                        <div class="flex flex-col w-full mb-4">
                            <label :class="['block text-sm font-medium mb-1', formErrors['message'] !== undefined ? 'text-red-700' : 'text-gray-700']">Message *</label>
                            <textarea rows="6"
                                      :class="[
                                              'w-full shadow-sm block w-full sm:text-sm border rounded-md focus:outline-none focus:ring-0 placeholder-gray-300',
                                              formErrors['message'] ? 'border-red-700' : 'border-gray-300'
                                              ]"
                                      placeholder="I would like to get more info about..."
                                      v-model="message"
                            />
                            <div v-if="formErrors['message'] !== undefined" class="mt-1 text-red-700 text-xs">
                                <i class="fal fa-exclamation-triangle text-sm mr-2"/> {{ formErrors['message'] }}
                            </div>
                        </div>

                        <autralis-select-multiple-comp :options="users"
                                                       :selectedOptions="selectedUsers"
                                                       @on-change="selectedUsers = $event"
                                                       placeholder="Users to send an email to"
                                                       label="Send to following users *"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InputGroup from "@/components/input-group";
import {handleFetchUsers} from "@/master/views/Home/company/handlers";
import {filter, map} from "lodash";
import SelectMultiple from "@/components/select-multiple";
import {handleSendMessage} from "@/master/views/Home/messages/handlers";
import SuccessBox from "@/components/success-box";
import ErrorBox from "@/components/error-box";

export default {
    name: "NewMessage",
    data() {
        return {
            centered: false,
            sending: false,
            sent: null,
            sendError: null,
            subject: '',
            message: '',
            selectedUsers: [],
            users: [],
            formErrors: {},
            fetchingUsers: false,
            fetchingUsersError: null
        }
    },

    methods: {
        async fetchUsers() {
            try {
                this.fetchingUsers = true;

                const payload = {
                    companyId: this.$store.getters['master/company'].id
                };

                await handleFetchUsers(payload, (response) => {
                    const currentLoggedUserId = this.$store.getters['master/user'].id;
                    this.users = map(filter(response.users, u => u.userId !== currentLoggedUserId), user => ({value: user.userId, text: user.firstName + ' ' + user.lastName}))
                }, (error) => {
                    this.fetchingUsersError = error
                })

                this.fetchingUsers = false;

            } catch (err) {
                this.fetchingUsersError = 'The message could not be deleted. Please try again.'
                this.fetchingUsers = false;
            }
        },

        validateForm() {
            let isValid = true;
            let errors = {};

            if (!this.subject) {
                errors['subject'] = "The subject cannot be empty.";
                isValid = false;
            }

            if (!this.message) {
                errors['message'] = "The message cannot be empty.";
                isValid = false
            }

            if (this.selectedUsers.length < 1) {
                errors['users'] = "At least one user needs to be chosen.";
                isValid = false
            }

            return {
                isValid,
                errors
            }
        },

        isValid() {
            this.formErrors = {};
            const {isValid, errors} = this.validateForm();
            this.formErrors = {...errors}
            return isValid
        },

        async sendMessage() {
            if (this.isValid()) {
                const payload = {
                    subject: this.subject,
                    message: this.message,
                    users: map(this.selectedUsers, user => user.value)
                }

                this.sending = true;
                this.sendError = null;

                await handleSendMessage(payload, () => {
                    this.subject = '';
                    this.message = '';
                    this.selectedUsers = [];
                    this.sent = true;
                }, (error) => {
                    this.sendError = error
                })

                this.sending = false;
            }
        },

        close() {
            this.$store.commit('master/setDetail', null);
            this.$store.commit('master/closeDetail');
        }
    },

    components: {
        'autralis-input-group-comp': InputGroup,
        'autralis-select-multiple-comp': SelectMultiple,
        'autralis-success-comp': SuccessBox,
        'autralis-error-comp': ErrorBox
    },

    created() {
        this.fetchUsers()
    }
}
</script>

<style scoped>

</style>