import axios from "axios";

export const handleFetchMessages = async (payload, onSuccess, onError) => {
    const msg_type = payload && payload.type ? payload.type : 'inbox';
    const response = await axios.get(
        '/manager/expert3/xp3/get-messages/' + msg_type + '/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        // const testMessages = [...response.data.messages, {...response.data.messages[0], id: 25}, {...response.data.messages[0], id: 35}]
        // onSuccess({...response.data, messages: testMessages})
        onSuccess(response.data)
    } else {
        onError('Loading messages failed. Please try again.')
    }
}

export const handleFetchMessage = async (payload, onSuccess, onError) => {
    const {messageId} = payload
    const response = await axios.get(
        '/manager/expert3/xp3/get-message/' + messageId + '/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading message failed. Please try again.')
    }
}

export const handleDeleteMessage = async (payload, onSuccess, onError) => {
    const {messageId} = payload
    const response = await axios.get(
        '/manager/expert3/xp3/delete-message/' + messageId + '/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Deleting message failed. Please try again.')
    }
}

export const handleUndeleteMessage = async (payload, onSuccess, onError) => {
    const {messageId} = payload
    const response = await axios.get(
        '/manager/expert3/xp3/undelete-message/' + messageId + '/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Undeleting message failed. Please try again.')
    }
}

export const handleSendMessage = async (payload, onSuccess, onError) => {
    const {subject, message, users} = payload
    const response = await axios.post(
        '/manager/expert3/xp3/send-message/',
        {
            subject,
            message,
            users
        },
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Send message failed. Please try again.')
    }
}